.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    
    @media (max-width: 768px) {
        padding: 10px;
    }
}

.itemss {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
}

.itemContainer {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative;

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        padding: 10px;
    }

    img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        margin-right: 20px;

        @media (max-width: 768px) {
            width: 100px;
            height: 100px;
            margin: 0 0 10px 0;
        }
    }
}

.wrapped {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
        margin-right: 0;
    }
}

.itemInfo {
    p {
        font-size: 18px;
        font-weight: 500;
    }
}

.btns {
    display: flex;
    align-items: center;
    gap: 15px;

    @media (max-width: 768px) {
        justify-content: center;
    }

    .btnItem {
        padding: 5px 15px;
        border: none;
        background: #f0f0f0;
        border-radius: 4px;
        cursor: pointer;
        font-size: 18px;
        transition: background 0.2s;

        &:hover {
            background: #e0e0e0;
        }
    }

    p {
        min-width: 30px;
        text-align: center;
    }
}

.price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 768px) {
        align-items: center;
    }

    .currentPrice {
        font-size: 18px;
        font-weight: 500;
    }

    .oldPrice {
        text-decoration: line-through;
        color: #999;
    }
}

.btn {
    cursor: pointer;
    padding: 5px 10px;
    
    p {
        font-size: 16px;
        color: #ff4444;
    }

    &:hover p {
        color: #ff0000;
    }
}

.clearBasket {
    text-align: center;
    padding: 40px;

    img {
        width: 200px;
        height: auto;
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        color: #666;
        margin-bottom: 20px;
    }

    button {
        background: linear-gradient(168deg, rgb(255, 141, 0), rgb(255, 49, 92));
        color: white;
        border: none;
        border-radius: 10px;
        padding: 13px 20px;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
        font-size: 13px;

        &:hover {
            opacity: 0.9;
        }
    }
}

.totalOrder {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 20px;

    @media (max-width: 768px) {
        padding: 15px;
    }

    .wrapper {
        p {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 15px;
        }
    }

    .greyBg {
        background: #f8f8f8;
        padding: 15px;
        border-radius: 4px;
        margin-bottom: 15px;
    }

    .total {
        p {
            font-size: 20px;
            font-weight: 600;
        }
    }

    .orderText {
        margin-bottom: 20px;
        p {
            font-size: 14px;
            color: #666;
        }
    }

    button {
        background: linear-gradient(168deg, rgb(255, 141, 0), rgb(255, 49, 92));
        color: white;
        border: none;
        border-radius: 10px;
        padding: 13px 0;
        width: 100%;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
        font-size: 13px;

        &:hover {
            opacity: 0.9;
        }
    }
}

// Стили для формы заказа
.getOrder {
    .headerItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 10px;
        }

        .back {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #666;

            &:hover {
                color: #333;
            }
        }

        .totalMoney {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .nameInputDiv {
        margin-bottom: 15px;

        label {
            display: block;
            margin-bottom: 5px;
            font-size: 14px;
            color: #666;
        }

        input, select {
            width: 100%;
            padding: 8px;
            border: 1px solid #ddd;
            border-radius: 4px;
            outline: none;

            &:focus {
                border-color: #007bff;
            }
        }

        .error {
            color: #ff4444;
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .dateDiv {
        margin-bottom: 15px;

        label {
            display: block;
            margin-bottom: 5px;
            font-size: 14px;
            color: #666;
        }

        .customDatepicker {
            width: 100%;
        }

        .error {
            color: #ff4444;
            font-size: 12px;
            margin-top: 5px;
        }
    }
}