.SomeBtns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
}
.line {
    width: 100%;
    height: 0.5px;
    background-color: rgb(175, 175, 175);
    opacity: 0.6;
    margin: 25px 0;
}
.infoBlocks {
    display: flex;
    @media (max-width: 1220px) {
      display: none;
    }
  .block {
    // padding: 0 7px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    width: 290px;
    height: 70px;
    border-radius: 15px;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.1);
    svg {
      margin-left: 7px;
    }
    p {
      width: 217px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.4;
      letter-spacing: 0em;
      text-align: left;
      span {
        color: rgb(247, 110, 6);
        font-weight: bold;
      }
    }
    &:not(:last-of-type) {
        margin-right: 10px;
      }
  }
}
.littleText {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0em;
  opacity: 0.9;
  color: #333;
  margin: 20px 0px;
}
.btns {
  @media (max-width: 1220px) {
    display: none;
  }
  padding: 0 15px;
  display: flex;
  h1 {
    position: fixed;
    font-size: 12px;
    top: 200px;
    left: 0px;
  }
  justify-content: space-between;
  button {
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 16px;
    padding: 18px 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    align-items: center;
    max-width: 100%;
    width: 100%;
    border: none;
    border-radius: 10px 10px 10px 10px;
    outline: none;
    transition: 150ms transform;
    box-shadow: 0 5px 15px 0px rgba(179, 99, 0, 0.2);
    width: 282px;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  
}
.orangeBtn {
  background: linear-gradient(168deg, rgb(255, 141, 0), rgb(255, 49, 92));
  &:hover {
    background: linear-gradient(168deg, rgb(230, 127, 0), rgb(255, 19, 68));
  }
}
.blueBtn {
  background: linear-gradient(168deg, rgb(17, 150, 255), rgb(0, 89, 198));
  &:hover {
    background: linear-gradient(168deg, rgb(0, 137, 245), rgb(0, 80, 178));
  }
}
.pinkBtn {
  background: linear-gradient(168deg, rgb(192, 81, 255), rgb(127, 0, 198));
  &:hover {
    background: linear-gradient(168deg, rgb(180, 48, 255), rgb(114, 0, 178));
  }
}
.darkBtn {
  background: linear-gradient(168deg, rgb(72, 8, 104), rgb(18, 1, 26));
  &:hover {
    background: linear-gradient(168deg, rgb(65, 7, 94), rgb(16, 1, 23));
  }
}
.btnsResponsible {
  display: none;
  @media (max-width: 1220px) {
    display: flex;
    flex-direction: column;
  }
  .item {
    display: flex;
    margin-top: 10px;
  }
  button {
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 13px;
    padding: 13px 0;
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
    align-items: center;
    max-width: 100%;
    width: 100%;
    border: none;
    border-radius: 10px 10px 10px 10px;
    outline: none;
    transition: 150ms transform;
    box-shadow: 0 5px 15px 0px rgba(179, 99, 0, 0.2);
    width: 160px;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
}}