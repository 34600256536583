.deskr {
    text-align: center;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0em;
    color: rgb(52, 55, 66);
    opacity: 0.8;
}
.blocks {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    .block {

        margin-top: 15px;
        margin-right: 35px;
        background-color: white;
        width: 261px;
        height: 345px;
        display: flex;
        flex-direction: column;
        border-radius: 21px;
        align-items: center;
        @media (max-width: 590px) {
            width: 70vw;
        }
        .semiBlock {
            width: 85%;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:last-child {
                margin-top: 35px;
            }
        }
        h2 {
            margin-top: 10px;
            font-size: 13px;
            font-weight: 700;
            color: #3D3D3D;
            text-align: center;
            line-height: 1.3;
            span {
                color: #FA5A06;
            }
            .red {
                color: #FF2936;
            }
        }
        p {
            margin-top: 20px;
            text-align: center;
            font-size: 13px;
        }
    }
}