
.securityContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  .headerSecurity {

    .descr {
      text-align: center;
      font-size: 16px;
      line-height: 1.6;
      opacity: 0.4;
    }
  }

  .rules {
    margin-top: 20px;
    display: flex;
    max-width: 1300px;
    flex-wrap: wrap;
    .item {
      padding: 25px;
      margin: 20px;
      background-color: rgb(255, 255, 255);
      border-radius: 15px;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
      width: 280px;

      .warning {
        color: rgb(238, 29, 36);
        font-size: 14px;
        line-height: 1.6;
        font-weight: bold;
        margin-top: 5px;
      }

      p {


        span {
          color: rgb(0, 166, 80);
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: 0em;
          text-decoration: underline;
          font-weight: bold;
        }
        div {
          text-align: left;
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: 0em;
        }
      }

      .secondBlock {
        margin-top: 20px;
        text-align: left;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: 0em;
      }
    }
  }
}

@media (max-width: 768px) {
  .rules {
    display: flex;
    justify-content: center;
  }
}