.container {
  margin-top: 100px;
  width: 1160px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  margin-bottom: 30px;

  .info {
    background-color: white;
    //height: 410px;
    width: 290px;
    margin-left: 25px;
    border-radius: 15px;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.1);
    padding-top: 25px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .someText {
      margin-top: 25px;
      text-align: center;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: -0.02em;
      opacity: 1;
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;

      .line {
        width: 15px;
        background-color: black;
        height: 2px;
      }

      p {
        text-align: center;
        font-size: 22px;
        line-height: 1.1;
        margin-left: 10px;
        margin-right: 10px;
        letter-spacing: 0em;
        color: rgb(52, 55, 66);
        font-weight: bolder;
      }
    }
  }

}

.contactsDiv {

  .contacts {
    padding: 25px;
    display: flex;
    background-image: linear-gradient(156deg, rgba(44, 47, 57, 1) 11%, rgba(31, 33, 40, 1) 48%, rgba(30, 32, 39, 1) 58%, rgba(19, 21, 25, 1) 83%);
    border-radius: 10px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);

    .line {
      margin-left: 20px;
      width: 1px;
      background-color: #5b5b5b;
    }

    .catalogs {
      margin-left: 40px;

      p {
        margin-top: 10px;
        text-align: left;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: 0em;
        color: rgb(255, 255, 255);
      }

      .items {
        p {
          text-align: left;
          font-size: 12px;
          line-height: 1.4;
          letter-spacing: 0em;
          color: rgb(255, 255, 255);
          opacity: 0.6;
          cursor: pointer;
        }
      }
    }

    .socialMedias {
      margin-left: 20px;

      .emailContainer {
        display: flex;
        align-items: center;
        margin-top: 10px;

        svg {
          background-color: white;
          border-radius: 50%;
          color: white
        }

        a {
          margin-left: 10px;
          text-decoration: none;
          text-align: left;
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: 0em;
          color: rgb(255, 255, 255);
          opacity: 1;
        }
      }

      .icons {
        // display: flex;
        margin-top: 10px;

        img {
          cursor: pointer;
        }
      }
    }

    h1 {
      text-align: left;
      font-size: 32px;
      line-height: 1.2;
      letter-spacing: 0em;
      color: rgb(255, 255, 255);
      opacity: 1;
    }

    p {
      margin-top: 10px;
      text-align: left;
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: 0em;
      color: rgb(255, 255, 255);
      opacity: 0.85;
    }

    .phones {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      a {
        text-decoration: none;
        color: rgb(255, 255, 255);
        z-index: 14;
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}

.hr {
  height: 1px;
  background-color: rgb(225, 225, 225);
  margin-top: 50px;
  margin-bottom: 50px;
}

.deliveryPlace {
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
  padding: 20px;

  p {
    margin-top: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0em;
    opacity: 0.85;
  }
}

.footerText {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  p {
    text-align: center;
    font-size: 10px;
    line-height: 1.6;
    letter-spacing: 0em;
    color: rgb(52, 55, 66);

  }
}

.img {
  width: 35px;
  height: 35px;
  // z-index: 100;
}

@media (max-width: 1200px) {
  .container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .contacts {
    width: 80%;
    flex-direction: column;
    //align-items: center;
  }
  .socialMedias {
    margin-left: 0px !important;
  }
  .catalogs {
    margin-left: 0px !important;
  }
  .deliveryPlace {
    width: 80%;
  }
  .contactsDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .info {
    width: 80% !important;
    margin-top: 10px;
    margin-left: 0px !important;
  }
}
.docs {
  margin-top: 25px;
  display: flex;
  img {
    cursor: pointer;
    margin-left: 10px;
    width: 110px;
    height: auto;
  }
}

@media (max-width: 500px) {
  .container {
    margin-top: 40px !important;
  }
  .hr {
    display: none;
  }
}
.iframe {
  width: 580px;
  height: 220px;
  margin-right: 30px;
}
.map {
  display: flex;
  justify-content: center;
}
@media (max-width: 1220px) {
  .map {
    flex-direction: column;
  }
  .iframe {
    margin-top: 15px;
    width: 350px !important;
    height: 220px !important;
  }
}