.container {
  height: 100vh;
  width: 100vw;
}

.wrapp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 30px;
  .productContainer {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .divBack {
      width: 100%;
      margin-bottom: 20px;
      .back {
        color: #5b5b5b;
        cursor: pointer;
        text-align: left;
        transition: .1s;
      }
      .back:hover {
        color: #414141;
      }
    }

    .btnsDiv {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      button {
        font-size: 12px;
        margin-top: 10px;
        width: 150px;
        height: 40px;
        outline: none;
        border: none;
        border-radius: 5px;
        background-color: #262626;
        color: white;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        transition: .1s;
      }
      .delete {
        background-color: #c20000;
      }
      .delete:hover {
        background-color: #d00000;
      }
    }

    h3 {
      margin-bottom: 10px;
      font-size: 18px;
    }

    .productInfo {
      display: flex;
      flex-direction: column;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }

      .title {
        font-weight: bold;
        margin-top: 3px;
        span {
          font-weight: normal;

          &.inStock {
            color: rgb(55, 180, 74);
          }
          
          &.outOfStock {
            color: #ff4d4f;
          }
        }
      }

      .category {
        margin-top: 5px;
      }

      .gramm {
        margin-top: 5px;
      }

      .priceInfo {
        margin: 10px 0;
        
        .price {
          font-weight: bold;
          span {
            color: #262626;
          }
        }

        .oldPrice {
          margin-top: 5px;
          span {
            color: #999;
            text-decoration: line-through;
          }
        }

        .discount {
          margin-top: 5px;
          span {
            color: #ff4d4f;
            font-weight: bold;
          }
        }
      }
    }
  }

}
