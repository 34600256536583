.modalBg {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  .modalCont {
    background: white;
    border-radius: 5px;
    z-index: 201;
    width: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-top: 0px;
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }

    input, textarea {
      width: 95%;
      margin-bottom: 15px;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      font-size: 14px;
      transition: 0.2s;

      &:focus {
        border-color: #1677ff;
        box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
        outline: none;
      }

      &:hover {
        border-color: #4096ff;
      }
    }

    textarea {
      min-height: 100px;
      resize: vertical;
    }

    .error {
      color: red;
      margin-bottom: 10px;
      text-align: center;
    }

    button {
      color: #fff;
      background: #1677ff;
      box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
      font-size: 16px;
      height: 40px;
      padding: 0 30px;
      border-radius: 6px;
      border: 0;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background: #4096ff;
      }
    }
  }
}

.active {
  opacity: 1;
  pointer-events: all;
} 