.modalBg {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  .content {
    // background: white;
    border-radius: 5px;
    z-index: 201;

  }
}

.iframe {
  width: 500px;
  height: 300px;
  border: none;
}
.active {
  opacity: 1 !important;
  pointer-events: all;
}
@media (max-width: 1200px) {
  .iframe {
    width: 300px;
    height: 200px;
  }
}