.bgModal {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: .3s;

  .content {
    position: absolute;
    right: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 0px;
    background-color: white;
    transition: .3s;

    .header {
      display: flex;
      margin-left: 18px;
      align-items: center;
      margin-top: 18px;

      .cross {
        cursor: pointer;
        background-color: rgba(42, 44, 53, 0.1);
        border-radius: 10px;
        padding: 13px;
        border: 0;
        font-size: 14px;
        color: rgb(0, 0, 0) !important;
        transition: .1s;

        svg {
          transition: .1s;
        }

        &:hover {
          background-color: rgb(60, 63, 76);

          svg {
            fill: rgb(255, 255, 255) !important;;
          }
        }
      }

      h2 {
        color: #333;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: 0em;
        margin-left: 80px;
        opacity: 1;
      }
    }

    .links {
      width: 100%;
      margin-top: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;

      a {
        margin-top: 10px;
        width: 85%;
        padding: 8px 0;
        cursor: pointer;
        padding-left: 10px;
        border-left: 1px solid rgb(87, 92, 110);;
        font-size: 15px;
        color: rgb(52, 55, 66) !important;
        text-decoration: none;

        &:hover {
          background-color: rgba(78, 82, 99, 0.05);
          border-color: rgb(255, 120, 15);
        }
      }

      a:hover {
        color: rgb(255, 120, 15) !important;
      }
    }
  }

  .contentOpened {
    width: 320px;
  }
}

.opened {
  opacity: 1;
  pointer-events: all;
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .catalog {
    margin-bottom: 10px;
    justify-content: center;
    height: 40px;
    background: linear-gradient(168deg, rgb(255, 141, 0), rgb(255, 49, 92));
    color: rgb(255, 255, 255);
    font-size: 15px;
    padding: 10px 35px;
    display: flex;
    position: relative;
    align-items: center;
    width: 80%;
    border: none;
    border-radius: 10px 10px 10px 10px;
    outline: none;
    transition: 150ms transform;
    box-shadow: 0 5px 15px 0px rgba(179, 99, 0, 0.2);
    cursor: pointer;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: flex;
        color: white;
        text-decoration: none;
      }
    }

    &:hover {
      background: linear-gradient(168deg, rgb(230, 127, 0), rgb(255, 19, 68));
    }
  }

  .whatsup {
    outline: none;
    border: none;
    background-color: #7E57C2;
    border-radius: 10px;
    width: 80%;
    padding: 8px 0px;
    font-size: 14px;
    color: rgb(255, 255, 255) !important;
    transition: .2s;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin-bottom: 10px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: flex;
        color: white;
        text-decoration: none;
      }
    }
    img {
      margin-top: 2px;
    }
    svg {
      margin-right: 5px;
      width: 25px;
      height: auto;
      path {
        fill: white;
      }
    }

    &:hover {
      background-color: #6b4aa3;
    }
  }

  .number {
    color: rgb(52, 55, 66);
    font-size: 24px;
    margin-bottom: 15px;
  }

  .line {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 80%;
    height: 1px;
    background-color: gray;
    opacity: .3;
  }

  .cart {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(52, 55, 66);
    border-radius: 50px;
    width: 80%;
    cursor: pointer;
    transition: .4s all;
    height: 40px;

    p {
      margin-left: 7px;
      color: rgb(52, 55, 66);
      font-size: 14px;
    }

    &:hover {
      background-color: rgb(52, 55, 66);

      p {
        color: white !important;
      }

      svg {
        path {
          fill: white;
        }
      }
    }
  }
}