.catalogText {
  margin-top: 0px;
  margin-bottom: 20px;
}
.catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
  .topItems {
    display: flex;
    justify-content: center;
    @media (max-width: 1220px) {
      flex-direction: column;
      justify-content: flex-start;
    }
    .item {
      padding: 10px;
      width: 280px;
      margin-left: 15px;
      display: flex;
      transition: .3s;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 195px;
      background-image: linear-gradient(to right, rgb(21, 21, 26) 0%, rgb(42, 44, 53) 100%);
      border-radius: 15px;
      box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.2);
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        transform: scale(1.08);
      }
      img {
        width: auto;
        height: 140px;
      }
      h2 {
        font-size: 16px;
        line-height: 1.1;
        letter-spacing: 0.02em;
        color: rgb(255, 255, 255);
        opacity: 1;
      } 
      p {
                margin-top: 10px;
                margin-bottom: 15px;
                text-align: left;
                font-size: 13px;
                line-height: 1.2;
                color: white;
                letter-spacing: 0em;
              }
              @media (max-width: 1220px) {
                width: 160px;
                position: relative;
                height: 155px;
                img {
                  height: 100px;
                  position: absolute;
                  margin-bottom: 50px;
                }
                  justify-content: flex-end;
                  h2 {
                    font-size: 12px;
                  }
                  p {
                    font-size: 9.5px;
                  }
              }
    }
  }
  .littleItems {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .item {
      transition: .3s;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      margin-left: 13px;
      img {
        margin-left: 15px;
        margin-right: 17px;
      }
      display: flex;
      align-items: center;
      background-image: linear-gradient(to right, rgb(21, 21, 26) 0%, rgb(42, 44, 53) 100%);
      border-radius: 15px;
      box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.2);
      width: 182px;
      height: 60px;
      h3 {
        color: white;
        font-size: 14px;
        //font-weight: normal;
      }
      &:hover {
        transform: scale(1.1);
      }
      @media (max-width: 1220px) {
        
        width: 160px;
      }

    }
    @media (max-width: 1220px) {
      flex-direction: column;
    }
  }
}
.item1488 {
  display: flex;
  margin-right: 15px;
  margin-top: 15px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1220px) {
    &:first-child {
      margin-top: 0;
    }
  }
}