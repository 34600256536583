@font-face {
  font-family: 'TT Norms';
  src: local('TT Norms Medium'), local('TT-Norms-Medium'),
      url('./fonts/TTNorms-Medium.woff2') format('woff2'),
      url('./fonts/TTNorms-Medium.woff') format('woff'),
      url('./fonts/TTNorms-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

* {
  /* font-family: "TT Norms"; */
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  width: 100vw;
}
body {
  background-color: #FAFAFA;
   overflow-x: hidden !important;
}
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector:hover,
:where(.css-dev-only-do-not-override-usln0u).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
:where(.css-dev-only-do-not-override-usln0u).ant-select-outlined:not(.ant-select-customize-input) ,
.ant-select-selector-focused,
.ant-select-open .ant-select-selector,
.ant-select-selector:focus-visible {
    border-color:  rgb(247, 110, 6) !important; /* You can customize this as well */
}
