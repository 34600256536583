.imgf {
    width: 200px;
    height: 200px;

}
.itemWrapper {
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    width: 224px;
    height: 100%;
    
    @media (max-width: 1220px) {
        width: 170px;
        .imgDiv {
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
    }
    
    @media (max-width: 500px) {
        width: 150px;
        margin: 5px;
        .imgDiv {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    
    @media (max-width: 387px) {
        width: 140px;
        .imgDiv {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .imgDiv {
        display: flex;
        justify-content: center;
    }

    .line {
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .productDescr {
        .descrItem {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .item {
                display: flex;
                align-items: center;
                background-color: rgb(248, 249, 250);
                border-radius: 8px;
                padding: 5px;

                p {
                    text-align: left;
                    font-size: 12px;
                    line-height: 1.6;
                    letter-spacing: 0em;
                    color: rgb(52, 55, 66);
                    opacity: 1;
                    margin-left: 3px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100px;
                }
            }
        }
    }

    .addToBasket {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        .ytBtn {
            width: 100%;
            border-radius: 10px;
            border-style: solid;
            display: flex;
            margin-bottom: 10px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            border-top-width: 2px;
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            background: white;
            border-color: rgb(244, 37, 34);
            padding-top: 7px;
            padding-bottom: 7px;
            font-size: 11px;
            color: rgb(244, 37, 34) !important;
        }
        .toCart {
            background: linear-gradient(170deg, rgb(250, 139, 54), rgb(247, 60, 5));
            color: rgb(255, 255, 255);
            font-size: 13px;
            font-weight: bold;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            width: 100%;
            border: none;
            border-radius: 10px;
            outline: none;
            transition: 150ms transform;
            box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.15);
            cursor: pointer;

            &.disabled {
                background: #e0e0e0;
                cursor: not-allowed;
                box-shadow: none;
                color: #666;
                background: linear-gradient(170deg, #cccccc, #999999);
                
                &:hover {
                    transform: none;
                }
            }
        }
        @media (max-width: 1220px) {
            button {
                font-size: 8px;
            }
        }
    }

    .titleDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        h3 {
            text-align: center;
            font-size: 18px;
            line-height: 1.2;
            letter-spacing: 0em;
            color: rgb(41, 41, 41);
            opacity: 1;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 2.4em;
        }

        .priceBlock {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 10px;

            .price {
                margin: 0;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                color: rgb(55, 180, 74);
            }

            .oldPrice {
                margin: 0;
                font-size: 16px;
                color: #999;
                text-decoration: line-through;
            }
        }

        .availability {
            margin-top: 5px;
            font-size: 12px;
            color: rgb(55, 180, 74);
            
            &.outOfStock {
                color: #ff4d4f;
            }
        }

        .descr {
            text-align: center;
            font-size: 13px;
            line-height: 1;
            letter-spacing: 0em;
            color: rgb(0, 0, 0);
            opacity: 0.5;
        }
        @media (max-width: 1220px) {
            h3 {
                font-size: 14px;
            }
            .priceBlock {
                .price {
                    font-size: 18px;
                }
                .oldPrice {
                    font-size: 14px;
                }
            }
            .availability {
                font-size: 11px;
            }
            .descr {
                font-size: 11px;
                width: 95%;
            }        
        }
    }
}

.article {
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0em;
    opacity: 0.75;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

