.container {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .product {
    background-color: white;
    width: 100%;
    max-width: 1200px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    display: flex;

    .rightSide {
      margin-left: 15px;
      padding-left: 10px;
      border-left: 1px solid rgba(52, 55, 66, 0.1);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      button {
        margin-top: 15px;
        background: linear-gradient(170deg, rgb(250, 139, 54), rgb(247, 60, 5));
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        padding: 10px 0;
        position: relative;
        cursor: pointer;
        width: 100%;
        border: none;
        border-radius: 10px 10px 10px 10px;
        outline: none;
        transition: 150ms transform;
        box-shadow: 0 5px 15px 0px rgba(148, 66, 4, 0.3);
        margin-bottom: 15px;

        &.disabled {
          background: #cccccc;
          cursor: not-allowed;
          box-shadow: none;
          
          &:hover {
            transform: none;
          }
        }
      }

      .headerItem {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        div {
          background-image: linear-gradient(to right, rgb(247, 110, 6) 0%, rgb(246, 46, 70) 100%);
          border-radius: 15px;
          width: 50%;
          display: flex;
          justify-content: center;
          color: white;
          padding: 5px;
        }
      }

      .lowerBlock {
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        img {
          height: 30px;

        }

        .block {
          display: flex;
          margin-left: 10px;

          div {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
          }

          b, p {
            font-size: 13px;
            line-height: 1.3;
            letter-spacing: 0em;
          }
        }
      }

      .content {
        margin-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .title {
          font-size: 20px;
          font-weight: 700;
        }

        .price {
          margin-top: 10px;
          font-size: 30px;
          font-weight: 500;
          opacity: 1;
        }

        .kiosk {
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: 0em;
          opacity: 0.75;
        }

        .article {
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: 0em;
          opacity: 0.75;
          margin-top: 5px;
        }

        .availability {
          font-size: 14px;
          margin: 10px 0;
          color: rgb(55, 180, 74);
          
          &.outOfStock {
            color: #ff4d4f;
          }
        }
      }
    }

    .info {
      display: flex;

      .photodiv {
        margin-left: 30px;

        img {
          width: 250px;
          height: 250px;
        }
      }

      .iframeItem {

        iframe {
          width: 550px;
          height: 300px;
        }
      }
    }


    .descr {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      .item {
        display: flex;
        align-items: center;
        background-color: rgb(248, 249, 250);
        border-radius: 8px;
        padding: 10px;
        min-width: 150px;
        max-width: 200px;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }

        div {
          p {
            font-size: 12px;
            color: #666;
            margin: 0;
          }

          h3 {
            font-size: 14px;
            color: #262626;
            margin: 0;
          }
        }
      }

      @media (max-width: 768px) {
        justify-content: center;
        
        .item {
          min-width: 130px;
          max-width: 45%;
        }
      }

      @media (max-width: 480px) {
        .item {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.itemb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .leftItem {
    height: 105px;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    width: 30%;

    img {
      height: 77px;
      width: 65px;
    }

    div {
      margin-left: 10px;

      b {
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: 0em;
        opacity: 1;
      }

      p {
        font-size: 13px;
        line-height: 1.2;
        letter-spacing: 0em;
        opacity: 1;
      }
    }
  }

  .rightItem {
    margin-left: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
    width: 31%;
    padding: 15px;
    b {
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: 0em;
      opacity: 1;
    }

    p {
      font-size: 13px;
      line-height: 1.3;
      letter-spacing: 0em;
      opacity: 1;
    }
  }
}

@media (max-width: 1208px) {
  .product {
    flex-direction: column;
    width: 330px !important;
  }
  iframe {
    width: 300px !important;
    height: 170px !important;
  }
  .info {
    flex-direction: column;
  }
  .headerItem {
    margin-top: 15px;
  }
  .rightSide {
    border-left: none !important;
    margin-left: 0px !important;
  }
  .item {
    flex-direction: column;
    width: 11% !important;
    align-items: center;
    justify-content: center;
    padding: 3px !important;
    p {
      font-size: 12px !important;
    }
  }
  .itemb {
    align-items: center;
    flex-direction: column;
    .rightItem {
      margin-top: 20px;
      width: 335px !important;
      margin-left: 0px !important;
    }
    .leftItem {
      width: 335px !important;
    }
  }
  .descr {
    width: 100% !important;
    justify-content: space-between;
  }
}
.svgicon {
  height: 20px;
  margin-right: 10px;
  width: 20px;
  path {
      fill: white
  }
}

.priceBlock {
    margin: 15px 0;

    .prices {
        display: flex;
        align-items: center;
        gap: 15px;

        .price {
            font-size: 24px;
            font-weight: bold;
            color: #262626;
        }

        .oldPrice {
            font-size: 20px;
            color: #999;
            text-decoration: line-through;
        }
    }
}

.availability {
    font-size: 16px;
    margin: 10px 0;
    color: rgb(55, 180, 74);
    
    &.outOfStock {
        color: #ff4d4f;
    }
}