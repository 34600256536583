.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  .modalContent {
    background: white;
    border-radius: 5px;
    z-index: 201;
    min-width: 400px;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
  }
}

.active {
  opacity: 1;
  pointer-events: all;
}

.form {
  .formwrapp {
    display: flex;
    gap: 30px;
    
    .rightCont {
      flex: 1;
    }
  }
}