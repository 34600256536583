.register {
    width: 100%;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        padding: 10px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: rgb(52, 55, 66);
    opacity: 1;
    }
    .content {
        margin-bottom: 25px;
    }
    input {
        height: 30px;
        padding: 5px;
        font-size: 16px;
        outline: none;
        transition: .2s;
        &:focus {
            border-color: rgb(0, 0, 0);
        }
        &:hover {
            border-color: rgb(0, 0, 0);
        }
    }
    .inputs {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .inputDiv {
            &:first-child {
                margin-top: 0;
            }
            margin-top: 20px;
        }
    }
    .btn {
        margin-top: 25px;
      width: 200px;
      height: 35px;
      outline: none;
      border: none;
      border-radius: 5px;
      background: linear-gradient(168deg, rgb(255, 141, 0), rgb(255, 49, 92));
      color: white;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      transition: .3s;
      &:hover {
          background: linear-gradient(168deg, rgb(230, 127, 0), rgb(255, 19, 68));
      }
    }
    p {
        margin-top: 25px;
        width: 90%;
        font-size: 18px;
        text-align: center;
        span {
            color: rgb(247, 110, 6);
            text-decoration: underline;
            cursor: pointer;
        }
    }
}