.Settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        margin-top: 0px;
        font-size: 21px;
        text-align: center;
        margin-bottom: 10px;
        width: 490px;
    }

    button {
        margin-top: 10px;
        width: 200px;
        height: 45px;
        outline: none;
        border: none;
        border-radius: 5px;
        background: linear-gradient(168deg, rgb(255, 141, 0), rgb(255, 49, 92));
        color: white;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        transition: .3s;

        &:hover {
            background: linear-gradient(168deg, rgb(230, 127, 0), rgb(255, 19, 68));
        }
    }
    @media (max-width: 1020px) {
        h2 {
            width: auto;
        }
    }
}