.headerWrapper {
    background-color: white;
    width: 100vw;
    border-bottom: 0.3px solid rgb(239, 239, 239);;
    height: 80px;
    display: flex;
    justify-content: center;
    @media (max-width: 1220px) {
        display: none;
    }
    
}
.headerWrapperFixed {
    z-index: 50;
    position: fixed;
    background-color: white;
    width: 100vw;
    border-bottom: 0.3px solid rgb(239, 239, 239);;
    height: 80px;
    display: flex;
    justify-content: center;
    @media (max-width: 1220px) {
        display: none;
    }
    a {
        cursor: pointer;
        margin-right: 20px;
        font-size: 13px;
        color: rgb(0, 0, 0)!important;
        text-decoration: none;
        font-weight: bold;
        .svg {
            transition: .3s;
            width: 13px;
            height: 13px;
        }
        transition: .3s;
        &:hover {
            color: rgb(247, 110, 6)!important;
            .svg {
                fill: rgb(247, 110, 6)!important;
            }
        }
    }
}

.headerDiv {
    display: flex;
    height: 100%;
    align-items: center;
    .imgae2 {
        width: 250px;
        height: auto;
    }
    img {
        cursor: pointer;
    }

    .catalog {
        justify-content: center;
        margin-left: 45px;
        height: 40px;
        background: linear-gradient(168deg, rgb(255, 141, 0), rgb(255, 49, 92));
        color: rgb(255, 255, 255);
        font-size: 15px;
        padding: 10px 35px;
        display: flex;
        position: relative;
        align-items: center;
        max-width: 100%;
        border: none;
        border-radius: 10px 10px 10px 10px;
        outline: none;
        transition: 150ms transform;
        box-shadow: 0 5px 15px 0px rgba(179, 99, 0, 0.2);
        cursor: pointer;
        &:hover {
            background: linear-gradient(168deg, rgb(230, 127, 0),rgb(255, 19, 68));
        }
    }
    .inputDiv {
        margin-left: 55px;
        display: flex;
        border: 1px solid rgb(170, 170, 170);
        border-radius: 45px;
        height: 40px;
        width: 232px;
        button {
            background-color: rgb(52, 55, 66);
            border-radius: 50%;
            width: 38px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            cursor: pointer;
            &:hover {
                background-color: rgb(66, 70, 83);
            }
        }
        input {
            display: flex;
            align-items: center;
            width: 84%;
            background-color: transparent;
            padding: 0px 10px;
            border: 0;
            outline: 0;
            height: 100%;
            transition: .3s all;
            &:focus {
                font-size: 12px;
            }
        }
    }
    .number {
        margin-left: 40px;
        color: rgb(52, 55, 66);
        font-size: 24px;
    }
    .cart {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid rgb(52, 55, 66);
        border-radius: 50px;
        width: 155px;
        cursor: pointer;
        transition: .4s all;
        height: 40px;
        p {
            margin-left: 7px;
            color: rgb(52, 55, 66);
            font-size: 14px;
        }
        &:hover {
            background-color: rgb(52, 55, 66);
            p {
                color: white !important;
            }
            svg {
                path {
                    fill: white;
                }
            }
        }
    }
    .menu {
        margin-left: 20px;
        cursor: pointer;
    }
}
.catalogDiv {
    position: absolute;
    transition: .4s;

}
.visible {
    visibility: visible;
    transition: visibility 0.3s, opacity 0.4s linear;
    opacity: 1;
    z-index: 3;

}
.hidden {
    visibility: hidden;
  opacity: 0;
  z-index: 3;
  transition: visibility 0.3s, opacity 0.4s linear;
}
.miniModal {
    position: absolute;
    width: 400px;
    z-index: 3 !important;
    min-height: 200px;
    background-color: white;
    box-shadow: -5px 6px 28px -1px rgba(0,0,0,0.27);
    border-radius: 6px;
    margin-left: -120px;
    margin-top: 10px;
    display: flex;
    padding: 20px;

    h3 {
        font-size: 14px;
        color: #333333;
        font-weight: normal;
        padding: 5px;
    }

    p {
        font-size: 12px;
        color: #343742;
        font-weight: bold;
        padding: 10px 5px;
        cursor: pointer;
        transition: .2s;

        &:hover {
            border-color: rgb(52, 55, 66);
            color: rgb(247, 110, 6)!important;
        }
    }

    .infoDiv {
        margin-top: 25px;
    }

    .vertical {
        padding: 0 15px;
        border-right: 1px solid rgba(52, 55, 66, 0.1);
    }

    .lastOne {
        border-right: 0 !important;
    }
}
.miniArrow {
    position: absolute;    
    width: 0;
  height: 0;
  margin-left: 113px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: 99;
}

.itemLink {
    z-index: 99;

    a {
        text-decoration: none;
        color: black;
        transition: .1s;
    }
    a:hover {
        color: rgb(247, 110, 6);
    }
}
.responsiveHeaderWrapper {
    width: 100vw;
    display: none;
    height: 80px;
    align-items: center;
    background-color: white;
    justify-content: center;
    border-bottom: 0.3px solid rgb(239, 239, 239);
    @media (max-width: 1220px) {
        display: flex;
    }
    .responsiveHeader {
        display: flex;
        align-items: center;
        .menu {
            cursor: pointer;
        }
        .search {
            margin-left: 20px;
            border: 0;
            position: relative;
            background-color: transparent;
            cursor: pointer;
                svg {
                    fill: #000000;
                }
        }
        .image2 {
            margin-left: 10px;
            width: 220px;
            height: auto;
        }
        .image {
            cursor: pointer;
            margin-left: 15px;
        }
        .searchModal {
            z-index: 999;
            display: flex;
            justify-content: center;
            align-items: center;
            //left: 10px;
            position: absolute;
            opacity: 0;
            pointer-events: none;
            height: 85px;
            transition: .2s;
            width: 335px;
            margin-left: -40px;
            margin-top: 15px;
            border-radius: 15px;
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(242, 242, 242);
            box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
        }
        .opened {
            opacity: 1;
            pointer-events: all;
        }
        .inputDiv {
            display: flex;
            border: 1px solid rgb(170, 170, 170);
            border-radius: 45px;
            height: 40px;
            width: 90%;
            button {
                background-color: rgb(52, 55, 66);
                border-radius: 50%;
                width: 38px;
                height: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0;
                cursor: pointer;
                &:hover {
                    background-color: rgb(66, 70, 83);
                }
            }
            input {
                display: flex;
                align-items: center;
                width: 88%;
                background-color: transparent;
                padding: 0px 10px;
                border: 0;
                outline: 0;
                height: 100%;
                transition: .3s all;
                &:focus {
                    font-size: 12px;
                }
            }
        }
    }
}
.HeaderResponsibleFixedWrapper {
    width: 100vw;
    display: none;
    height: 80px;
    z-index: 99;
    opacity: 0;
    transition: .2s;
    pointer-events: none;
    align-items: center;
    background-color: white;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    border-bottom: 0.3px solid rgb(239, 239, 239);
    @media (max-width: 1220px) {
        display: flex;
    }
    .menu {
        cursor: pointer;
    }
    .number {
        margin-left: 20px;
        color: rgb(52, 55, 66);
        font-size: 24px;
    }
    .image {
        margin-left: 25px;
        cursor: pointer;
    }
    .bottomItems {
        margin-top: 10px;
        display: flex;
        p {
            font-size: 11px;
            font-weight: bold;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }

}
.openedFixed {
    opacity: 1;
    pointer-events: all;

}
.topItems {
    display: flex;
}