.title {
    display: flex;
    justify-content: center;
    align-items: center;

    .line {
        width: 40px;
        background-color: rgb(247, 110, 6);
        height: 2px;
    }

    p {
        padding: 10px;
        text-align: center;
        font-size: 21px;
        font-weight: bold;
        color: rgb(52, 55, 66);
        opacity: 1;
    }
}