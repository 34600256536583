.discounts {
    margin-top: 30px;
    margin-bottom: 40px;
}

.discountBlock {
    margin-top: 20px;
    background-image: linear-gradient(to right, rgb(21, 21, 26) 0%, rgb(42, 44, 53) 100%);
    border-radius: 15px;
    box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.2);
    width: 390px;
    height: 200px;
    display: flex;
    align-items: center;
    
    &:not(:last-child) {
        margin-right: 30px;
    }

    div {
        margin-left: 15px;
        flex: 1;
        max-width: 60%;
    }

    h1 {
        color: white;
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: 0.02em;
        text-align: left;

        span {
            color: rgb(250, 124, 29);
        }
    }

    p {
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: left;
        font-size: 13px;
        line-height: 1.4;
        color: white;
        letter-spacing: 0em;
        
        b {
            color: rgb(250, 124, 29);
        }
    }

    img {
        width: 180px;
        height: 180px;
        margin-right: 15px;
        object-fit: cover;
        border-radius: 10px;
    }

    @media (max-width: 1220px) {
        width: 330px;
        height: 160px;
        margin-right: 0 !important;
        
        div {
            max-width: 65%;
        }

        h1 {
            font-size: 16px;
        }

        p {
            font-size: 12px;
        }

        img {
            width: 100px;
            height: 100px;
        }
    }
}

.blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    @media (max-width: 1220px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
}