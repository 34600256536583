.container {
  margin-top: 20px;

  .infoContainer {
    background-color: white;
    min-height: 350px;
    margin-top: 20px;
    // width: 900px;
    padding-left: 20px;
    border-radius: 15px;
    padding: 30px;
    align-items: center;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    
    // justify-content: center;
    h2 {
        margin-top: 25px;
      font-size: 18px;
      line-height: 1.2;
      letter-spacing: 0em;
    }
    @media (max-width: 1220px) {
        padding: 12px 15px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 330px;
        h2 {
            text-align: center;
            font-size: 14px;
        }
    }
  }
}
.hide {
    @media (max-width: 1220px) {
        display: none !important;
    }
}
.responsibleDiv {
    display: none !important;
    @media (max-width: 1220px) {
        display: flex !important;
    }
}
.responsible {
    display: none !important;
    margin-top: 0 !important;
    margin-left: 10px !important;
    width: 285px !important;
    height: 60px !important;
    p {
        font-size: 14px !important;
    }
    @media (max-width: 1220px) {
        display: flex !important;
    }
}
.delivery {
    margin-top: 25px;
    display: flex;
    align-items: center;
    img {
        // width: 75px;
        // height: auto;
        @media (max-width: 1220px) {
            height: auto;
            width: 45px;
        }
    }
    .infoDiv {
        p {
            width: 300px;
            margin-left: 10px;
            font-size: 14px;
            line-height: 1.4;
            letter-spacing: 0em;
            span {
                color: rgb(241, 101, 34);
            }
        }
        @media (max-width: 1220px) {
            p {
                width: auto;
                font-size: 12px;
                word-wrap: normal;
            }
        }
    }
    @media (max-width: 1220px) {
        justify-content: space-between;
    }
}
.line {
    width: 1px;
    background-color: gray;
    opacity: 0.3;
    height: 300px;
    @media (max-width: 1220px) {
        display: none;
    }
}
.freeDelivery {
    margin-left: 20px;
    margin-top: 25px;
    padding: 5px 20px;
    width: 330px;
    height: 60px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(to right, rgb(255, 125, 0) 0%, rgb(231, 77, 0) 100%);
    border-radius: 15px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.3);
    p {
        font-weight: bold;
        width: 175px;
        color: white;
    }
    @media (max-width: 1220px) {
        display: none;
    }
}
.margin {
    margin-left: 20px;
    @media (max-width: 1220px) {
        margin-left: 0;
    }
}
.leftContainer {
    margin-right: 25px;
    @media (max-width: 1220px) {
        width: 99%;
        margin-right: 0;
    }
}