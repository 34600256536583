.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1160px;
  margin-right: auto;
  margin-left: auto;

  .btnText {
    margin-top: 20px;
    width: 100%;

    .textDesct {
      text-align: center;
      font-size: 16px;
      line-height: 1.6;
      font-weight: 350;
      letter-spacing: 0em;
      color: rgb(52, 55, 66);
      opacity: 0.8;
    }
  }

  .filter {
    overflow: hidden;
    margin-top: 35px;
    width: 100%;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    transition: max-height ease-in-out .3s;
    max-height: 45px;
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.05);

    .textBlock {
      padding: 10px 20px;
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 14px;
        color: rgb(52, 55, 66);
        opacity: 0.8;
      }

      p {
        font-size: 20px;
        color: rgb(52, 55, 66);
      }
    }

    .filterContent {
      padding: 0 20px 20px;

      .inputItem {
        margin-bottom: 20px;
        
        p {
          margin-bottom: 10px;
          font-size: 14px;
          color: #333;
        }

        :global(.ant-select) {
          width: 100%;
          margin-bottom: 10px;
        }

        :global(.ant-select-selector) {
          border-radius: 4px !important;
        }
      }

      .filterButtons {
        display: flex;
        gap: 15px;
        margin-top: 20px;

        button {
          padding: 8px 20px;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          font-size: 14px;
          transition: all 0.2s;

          &:first-child {
            background: linear-gradient(170deg, rgb(250, 139, 54), rgb(247, 60, 5));
            color: white;
            
            &:hover {
              opacity: 0.9;
            }
          }

          &.resetButton {
            background: #f5f5f5;
            color: rgb(52, 55, 66);

            &:hover {
              background: #e5e5e5;
            }
          }
        }
      }
    }
  }

  .filter.active {
    max-height: 500px;
  }

  .productItem {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  .noProducts {
    text-align: center;
    color: rgb(52, 55, 66);
    font-size: 16px;
    margin: 20px 0;
  }
}

@media (max-width: 1200px) {
  .wrapper {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: 95%;

    .filter {
      .filterContent {
        .filterButtons {
          flex-direction: column;
        }
      }
    }
  }
}