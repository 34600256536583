.ChangePassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
    .ownPassword {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
        h1 {
            font-size: 28px;
            font-weight: 500;
        }
        .inputBlog {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h2 {
                font-size: 17px;
                margin-right: 5px;
                font-weight: 400;
            }
            input {
                height: 30px;
                padding: 5px;
                font-size: 16px;
                outline: none;
                transition: .2s;
                &:focus {
                    border-color: rgb(0, 0, 0);
                }
                &:hover {
                    border-color: rgb(0, 0, 0);
                }
            }
        }
    }
    .btn {
        // border: 0;
        // border-top: 1px solid rgb(52, 55, 66);
        // border-bottom: 1px solid rgb(52, 55, 66);
        // padding: 15px;
        // margin-top: 35px;
        // width: 80%;
        // background-color: transparent;
        // cursor: pointer;
        // transition: .2s;
        // &:hover {
        //     color: rgb(247, 110, 6);
        //     background-color: rgba(0, 0, 0, 0.027);
        //   }
        margin-top: 30px;
        width: 200px;
        height: 45px;
        outline: none;
        border: none;
        border-radius: 5px;
        background: linear-gradient(168deg, rgb(255, 141, 0), rgb(255, 49, 92));
        color: white;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        transition: .3s;
        &:hover {
            background: linear-gradient(168deg, rgb(230, 127, 0), rgb(255, 19, 68));
        }
    }
}