.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  
    .modalContent {
      background: white;
      border-radius: 5px;
      z-index: 201;
      min-width: 400px;
      padding: 20px;
  
    }
  }
  
  
  .modalBg {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  
    .modalCont {
      background: white;
      border-radius: 5px;
      z-index: 201;
      min-width: 400px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        margin-top: 0px;
        font-size: 16px;
        text-align: center;
        margin-bottom: 10px;
      }
      input {
        border-radius: 6px;
        width: 95%;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        background: #ffffff;
        border-width: 1px;
        border-style: solid;
        border-color: #d9d9d9;
        transition: .2s;
  
        &:focus {
            border-color: #1677ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
            outline: 0;
            background-color: #ffffff;
        }
  
        &:hover {
            border-color: #4096ff;
            background-color: #ffffff;
        }
      }
      button {
        color: #fff;
          background: #1677ff;
          box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
          font-size: 14px;
          line-height: 1.5714285714285714;
          height: 32px;
          padding: 4px 15px;
          border-radius: 6px;
          border: 0;
          transition: .2s;
          cursor: pointer;
          margin-top: 8px;
          &:hover {
              color: #fff;
              background: #4096ff;
          }
      }
  
    }
  }
  .active {
    opacity: 1;
    pointer-events: all;
  }